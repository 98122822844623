import {Dispatch} from "redux";

import {TravelTimeTransport} from "../types/TravelTimeTransport";
import {TravelTimeValues} from "../types/TravelTimeValues";

export interface ITravelTimeData {
    travel_time: TravelTimeValues;
    transport_type: TravelTimeTransport;
}

export interface ITravelTimeStore {
    data: ITravelTimeData;
    prevDistance: string | number | undefined;
}

export interface ISetTravelTimeAction {
    type: string;
    currentTravelTime?: ITravelTimeData;
    prevDistance?: string | number;
}

export const SET_TRAVEL_TIME_DATA = "search_engine/SET_TRAVEL_TIME_DATA";
export const SET_PREVIOUS_DISTANCE = "search_engine/SET_PREVIOUS_DISTANCE";
export const SET_TRAVEL_TIME_ACTIVE = "search_engine/SET_TRAVEL_TIME_ACTIVE";

export const setTravelTimeData = (currentTravelTime: ITravelTimeData) => (dispatch: Dispatch<ISetTravelTimeAction>) => {
    dispatch<ISetTravelTimeAction>({type: SET_TRAVEL_TIME_DATA, currentTravelTime});
};

export const setPreviousDistance = (prevDistance: string | number | undefined) => (dispatch: Dispatch<ISetTravelTimeAction>) => {
    dispatch<ISetTravelTimeAction>({type: SET_PREVIOUS_DISTANCE, prevDistance});
};
import {Dispatch} from "redux";

import {TravelTimeTransport} from "../types/TravelTimeTransport";
import {TravelTimeValues} from "../types/TravelTimeValues";

export interface ITravelTimeData {
    travel_time: TravelTimeValues;
    transport_type: TravelTimeTransport;
}

export interface ITravelTimeStore {
    data: ITravelTimeData;
    prevDistance: string | number | undefined;
}

export interface ISetTravelTimeAction {
    type: string;
    currentTravelTime?: ITravelTimeData;
    prevDistance?: string | number;
}

export const SET_TRAVEL_TIME_DATA = "search_engine/SET_TRAVEL_TIME_DATA";
export const SET_PREVIOUS_DISTANCE = "search_engine/SET_PREVIOUS_DISTANCE";
export const SET_TRAVEL_TIME_ACTIVE = "search_engine/SET_TRAVEL_TIME_ACTIVE";

export const setTravelTimeData = (currentTravelTime: ITravelTimeData) => (dispatch: Dispatch<ISetTravelTimeAction>) => {
    dispatch<ISetTravelTimeAction>({type: SET_TRAVEL_TIME_DATA, currentTravelTime});
};

export const setPreviousDistance = (prevDistance: string | number | undefined) => (dispatch: Dispatch<ISetTravelTimeAction>) => {
    dispatch<ISetTravelTimeAction>({type: SET_PREVIOUS_DISTANCE, prevDistance});
};
